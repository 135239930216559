<template>
  <TitleComponent :text="contactsLabel" />
  <div v-if="phoneNumber" class="contact-line">
    <img src="@/assets/phone.svg" class="contact-icon phone-icon" />
    <ParagraphComponent class="contact" :text="phoneNumber" />
  </div>
  <div v-if="email" class="contact-line">
    <img src="@/assets/email.svg" class="contact-icon email-icon" />
    <ParagraphComponent class="contact" :text="email" :link="emailLink" />
  </div>
  <div v-if="email" class="contact-line">
    <img src="@/assets/linkedin.svg" class="contact-icon linkedin-icon" />
    <ParagraphComponent class="contact" :text="linkedIn" :link="linkedInLink" />
  </div>
</template>

<script>
import TitleComponent from "@/components/TitleComponent.vue";
import ParagraphComponent from "@/components/ParagraphComponent.vue";

export default {
  name: "ContactsSection",

  components: { TitleComponent, ParagraphComponent },

  computed: {
    currentLanguage() {
      return this.$store.state.currentLanguage;
    },

    contactsLabel() {
      return this.currentLanguage.resumeLabels.contacts;
    },

    contacts() {
      return this.currentLanguage.data.contacts;
    },

    phoneNumber() {
      return this.contacts.phone;
    },

    email() {
      return this.contacts.email;
    },

    emailLink() {
      return this.contacts.emailLink;
    },

    linkedIn() {
      return this.contacts.linkedIn;
    },

    linkedInLink() {
      return this.contacts.linkedInLink;
    },
  },
};
</script>

<style scoped>
.contact-line {
  display: flex;
  margin: 0 auto;
  align-items: center;
}

.contact {
  padding-left: 1em;
  margin: 0.25em;
}
</style>
