<template>
  <div class="wrapper">
    <div class="header">
      <HeaderSection />
    </div>
    <div class="contacts">
      <ContactsSection />
    </div>
    <div class="profile">
      <ProfileSection />
    </div>
    <div class="skills">
      <SkillsSection />
    </div>
    <div class="experience">
      <ExperienceSection />
    </div>
    <div class="education">
      <EducationSection />
    </div>
    <div class="project">
      <ProjectSection />
    </div>
    <!-- <div class="expertise">
      <h3>EXPERTISE</h3>
    </div> -->
  </div>
</template>

<script>
import ContactsSection from "@/components/ContactsSection.vue";
import ProfileSection from "@/components/ProfileSection.vue";
import HeaderSection from "@/components/HeaderSection.vue";
import SkillsSection from "@/components/SkillsSection.vue";
import ExperienceSection from "@/components/ExperienceSection.vue";
import ProjectSection from "@/components/ProjectSection.vue";
import EducationSection from "@/components/EducationSection.vue";

export default {
  name: "ResumeView",

  components: {
    ContactsSection,
    ProfileSection,
    HeaderSection,
    SkillsSection,
    ExperienceSection,
    ProjectSection,
    EducationSection,
  },
};
</script>

<style scoped>
.wrapper {
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-template-rows: 190px auto auto auto auto;
  grid-template-areas:
    "header header"
    "contacts profile"
    "skills experience"
    "education experience"
    "expertise experience"
    "expertise project";
}

.contacts,
.skills,
.education,
.expertise {
  padding: 0px 45px 0 45px;
  margin: 0px 0px 45px 0px;
}

.profile,
.experience,
.project {
  padding: 0px 45px 0 0;
  margin: 0px 0px 45px 0px;
}

.experience,
.project,
.expertise {
  margin: 0;
}

.header {
  display: flex;
  grid-area: header;
  background-image: url("~@/assets/wave.svg");
  background-repeat: no-repeat;
}

.contacts {
  grid-area: contacts;
}

.profile {
  grid-area: profile;
}

.skills {
  grid-area: skills;
}

.experience {
  grid-area: experience;
}

.project {
  grid-area: project;
}

.education {
  grid-area: education;
}

.expertise {
  grid-area: expertise;
}

@media (max-width: 21cm) {
  .wrapper {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(7, auto);
    grid-template-areas:
      "header"
      "contacts"
      "profile"
      "experience"
      "project"
      "skills"
      "education"
      "expertise";
  }

  .header {
    background-image: url("~@/assets/wave-mobile.svg") !important;
  }

  .contacts,
  .skills,
  .profile,
  .experience,
  .project,
  .expertise,
  .skills,
  .education {
    padding: 0px 20px 0 20px !important;
  }
}

@media print {
  .wrapper {
    grid-template-areas:
      "header header"
      "contacts profile"
      "skills experience"
      "education experience"
      "project experience"
      "expertise experience";
  }
}
</style>
