<template>
  <TitleComponent :text="profileLabel" />
  <ParagraphComponent :text="profile" />
</template>

<script>
import TitleComponent from "@/components/TitleComponent.vue";
import ParagraphComponent from "@/components/ParagraphComponent.vue";

export default {
  name: "ProfileSection",

  components: { TitleComponent, ParagraphComponent },

  computed: {
    currentLanguage() {
      return this.$store.state.currentLanguage;
    },

    profileLabel() {
      return this.currentLanguage.resumeLabels.profile;
    },

    profile() {
      return this.currentLanguage.data.profile;
    },
  },
};
</script>
