<template>
  <TitleComponent :text="educationLabel" />
  <section
    class="education-section"
    v-for="(education, index) in educations"
    :key="index"
  >
    <HeadlineComponent :text="education.degreeTitle" />
    <ParagraphComponent :text="education.institutionName" />
    <ParagraphComponent :text="education.studyDirection" />
  </section>
</template>

<script>
import TitleComponent from "@/components/TitleComponent.vue";
import HeadlineComponent from "@/components/HeadlineComponent.vue";
import ParagraphComponent from "@/components/ParagraphComponent.vue";

export default {
  Name: "Education",

  components: { TitleComponent, HeadlineComponent, ParagraphComponent },

  computed: {
    currentLanguage() {
      return this.$store.state.currentLanguage;
    },

    educationLabel() {
      return this.currentLanguage.resumeLabels.education;
    },

    educations() {
      return this.currentLanguage.data.education;
    },
  },
};
</script>

<style scoped>
.education-section {
  margin-bottom: 2em;
}

.education-section:last-child {
  margin-bottom: 0;
}
</style>
