<template>
  <TitleComponent :text="projectLabel" />
  <section
    class="project-section"
    v-for="(project, index) in projects"
    :key="index"
  >
    <section class="project-title-section">
      <SubTitle :text="project.projectTitle" />
      <SubTitle
        class="project-title-section-period"
        :text="project.projectPeriod"
      />
    </section>

    <HeadlineComponent :text="project.companyName" />
    <ParagraphComponent :text="project.description" />
    <ParagraphComponent
      v-if="project.listItems"
      :isList="true"
      :listItems="project.listItems"
    />
  </section>
</template>

<script>
import TitleComponent from "@/components/TitleComponent.vue";
import SubTitle from "@/components/SubTitle.vue";
import HeadlineComponent from "@/components/HeadlineComponent.vue";
import ParagraphComponent from "@/components/ParagraphComponent.vue";

export default {
  Name: "Project",

  components: {
    TitleComponent,
    SubTitle,
    HeadlineComponent,
    ParagraphComponent,
  },

  computed: {
    currentLanguage() {
      return this.$store.state.currentLanguage;
    },

    projectLabel() {
      return this.currentLanguage.resumeLabels.project;
    },

    projects() {
      return this.currentLanguage.data.project.map((project) => {
        if (project.description.includes("•")) {
          const [desc, ...listItems] = project.description
            .split("•")
            .map((item) => item.trim())
            .filter((item) => item);
          return { ...project, description: desc, listItems };
        }
        return project;
      });
    },
  },
};
</script>

<style scoped>
.project-section {
  margin-bottom: 2em;
}

.project-title-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.project-title-section-period {
  letter-spacing: 1px;
  font-size: 13px;
  font-weight: 700;
  text-transform: capitalize;
}
</style>
