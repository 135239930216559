<template>
  <div>
    <p v-if="!isList">{{ text }}</p>
    <ul v-else>
      <li v-for="item in listItems" :key="item">{{ item }}</li>
    </ul>
  </div>
</template>

<script>
export default {
  Name: "ParagraphComponent",

  props: {
    text: {
      type: [String, Number],
      required: true,
    },
    isList: {
      type: Boolean,
      default: false,
    },
    listItems: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style scoped>
p,
a {
  line-height: 22px;
  margin: 0;
  font-size: 15px;
  color: #081c15;
}
a:hover {
  color: #40916c;
}

ul {
  margin: 0;
  padding-left: 20px;
}

li {
  line-height: 22px;
  font-size: 13px;
  color: #081c15;
}
</style>
