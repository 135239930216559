import enData from "../../resume/en-data.yaml";
import ruData from "../../resume/ru-data.yaml";
import frData from "../../resume/fr-data.yaml";
import cnData from "../../resume/cn-data.yaml";

export const languages = [
  {
    name: "English",
    code: "en",
    data: enData,
    resumeLabels: {
      contacts: "Contacts",
      profile: "Profile",
      skills: "Skills",
      experience: "Experience",
      professional: "Professional",
      education: "Education",
      project: "Project",
    },
  },
  {
    name: "Русский",
    code: "ru",
    data: ruData,
    resumeLabels: {
      contacts: "Контакты",
      profile: "Профиль",
      skills: "Навыки",
      experience: "Опыт работы",
      professional: "Профессиональные",
      education: "Образование",
    },
  },
  {
    name: "Français",
    code: "fr",
    data: frData,
    resumeLabels: {
      contacts: "Contacts",
      profile: "Profil",
      skills: "Compétences",
      experience: "Expérience",
      professional: "professionnelle",
      education: "Formation",
      project: "Projet",
    },
  },
  {
    name: "简体中文",
    code: "cn",
    data: cnData,
    resumeLabels: {
      contacts: "联系方式",
      profile: "个人简介",
      skills: "技能",
      experience: "经验",
      professional: "专业",
      education: "教育",
      project: "项目",
    },
  },
];

export function getCurrentLanguage() {
  return languages[0];
}

export function getLanguages() {
  return languages;
}
